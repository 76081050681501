import React from 'react';
import { graphql } from 'gatsby';

import { linkResolver } from '../utils';

import { Layout, TitleAndMeta, Archive, WorkCard, Row } from '../components';

export default ({ data }) => {
  const works = data.prismic.allWorks.edges;

  return (
    <Layout>
      <TitleAndMeta title="Works" />
      <Archive title="Works">
        <Row as="ul" wrap>
          {works.map(({ node: work }) => (
            <WorkCard
              to={linkResolver(work)}
              key={work._meta.uid}
              title={work.title[0].text}
            />
          ))}
        </Row>
      </Archive>
    </Layout>
  );
};

export const query = graphql`
  query AllWorksQuery {
    prismic {
      allWorks(sortBy: publication_date_DESC) {
        edges {
          cursor
          node {
            title
            _meta {
              type
              uid
            }
          }
        }
      }
    }
  }
`;
